import * as yup from 'yup';
import dayjs from 'dayjs';

export const validationSchool = yup.object().shape({
	state_id: yup.number().required('Required'),
	location_id: yup.number().required('Required'),
	booking_for: yup.number().required('Required'),
	booking_name: yup.string().required('Required'),
	booking_type_id: yup.number().required('Required'),
	first_name: yup.string().required('Required'),
	email: yup.string().email().required('Required'),
	mobile: yup.string().required('Required')
});

export const formValues = {
	state_id: '',
	location_id: '',
	booking_for: '',
	booking_name: '',
	booking_type_id: '',
	lesson_duration_id: '',
	booking_duration_id: '',
	booking_sub_type_id: '',
	booking_start_date: null,
	lesson_start_time: '09:00',
	first_name: '',
	last_name: '',
	email: '',
	mobile: '',
	extra_facility_requirements: [],
	buses_required: '',
	people_under_5: '',
	people_between_5_to_9: '',
	people_between_10_to_15: '',
	people_above_16: '',
	total_people: '',
	medical_conditions: '',
	additional_comments: ''
};
