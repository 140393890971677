import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Gym from './components/gym';
import GymSuccess from './components/gymsuccess';
import School from './components/school';
import Success from './components/success';
import Voucher from './components/voucherservice';
import VoucherSuccess from './components/vouchersuccess';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/gym" element={<Gym />} />
      <Route path="/gymsuccess" element={<GymSuccess />} />
      <Route path="/school" element={<School />} />
      <Route path="/success" element={<Success />} />
      <Route path="/voucherservice" element={<Voucher />} />
      <Route path="/vouchersuccess" element={<VoucherSuccess />} />
    </Routes>
  </Router>
);

export default App;
