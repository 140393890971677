/* eslint-disable camelcase */
import React, { useState, useEffect, createRef } from 'react';
import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { Checkbox, FormControlLabel, Button } from '@material-ui/core';
import {
	FormikCheckbox,
	FormikTime,
	FormikTextField,
	FormikSelect,
	FormikRadioButton,
	FormikPicker
} from '../formik-material';
import PropTypes from 'prop-types';
import generateSchoolCrud from './school.crud';

SchoolForm.propTypes = {
	toApiUrl: PropTypes.func,
	editMode: PropTypes.bool
};

const bookingGroup = [
	{ id: 1, name: 'School' },
	{ id: 2, name: 'Group' }
];

export default function SchoolForm({ toApiUrl, editMode = false, capchaKey }) {
	const captchaRef = createRef();
	const [termsCheckbox, setTermsCheckbox] = useState(false);
	const { values, setFieldValue, isSubmitting } = useFormikContext();
	const [subTypeLabel, setSubTypeLabel] = useState('');
	const [capchaValue, setChachaValue] = useState(false);

	// Locations is split into its own state due to the racing conditions of previuos data when
	// requesting from editMode, locations will be resolved first, thus everything is overwriten with empty array
	const [selectionItems, setSelectionItems] = useState({
		states: [],
		bookingTypes: [],
		bookingSubTypes: [],
		lessonDurations: [],
		bookingDurations: []
	});
	const [bookingTypeId, setBookingTypeId] = useState(0);
	const [locations, setLocations] = useState([]);
	const {
		getLocations,
		getStates,
		getBookingTypes,
		getBookingSubTypes,
		getLessonDuration,
		getBookingDuration,
		getServices,
		checkCaptcha
	} = generateSchoolCrud(toApiUrl);

	useEffect(() => {
		async function getData() {
			try {
				const [states, bookingTypes, lessonDurations, bookingDurations] = await Promise.all([
					getStates(),
					getBookingTypes(),
					getLessonDuration(),
					getBookingDuration()
				]);

				if (states && bookingTypes && lessonDurations && bookingDurations) {
					setSelectionItems(prevState => ({
						...prevState,
						states: states.data,
						bookingTypes: bookingTypes.data,
						lessonDurations: lessonDurations.data,
						bookingDurations: bookingDurations.data
					}));
				}
			} catch (error) {}
		}

		getData();
	}, []);

	useEffect(() => {
		async function getData() {
			const index = selectionItems.bookingTypes.findIndex(item => item.id === bookingTypeId);

			if (index >= 0) {
				setSubTypeLabel(selectionItems.bookingTypes[index].name);
				try {
					const [subTypes, services] = await Promise.all([
						getBookingSubTypes(bookingTypeId),
						getServices(bookingTypeId)
					]);

					if (subTypes && services) {
						setSelectionItems({
							...selectionItems,
							bookingSubTypes: subTypes.data
						});
						const newService = services.data.map(item => ({
							...item,
							service_items: item.serviceItems.map(serviceItem => ({
								...serviceItem,
								is_checked: false
							})),
							is_checked: false,
							value: ''
						}));

						setFieldValue('extra_facility_requirements', newService);
					}
				} catch (error) {
					console.log('Goes in error of booking type change: ', error.message);
				}
			}

			setFieldValue('booking_type_id', bookingTypeId);
		}
		getData();
	}, [bookingTypeId]);

	const handleCapchaChange = async value => {
		try {
			const captchaResponse = await checkCaptcha({ response: value });
			if (captchaResponse.data.success) {
				setChachaValue(true);
			}
		} catch (e) {
			captchaRef.current.reset();
		}
	};

	const handleBookingTypeChange = async e => {
		setBookingTypeId(e);
	};

	const getSetLocations = async id => {
		try {
			const response = await getLocations(id);
			if (response) {
				setLocations(response.data);
			}
		} catch (error) {}
	};

	const handleStateChange = e => {
		getSetLocations(e);
		setFieldValue('state_id', e);
	};

	const handleTermChange = e => {
		setTermsCheckbox(e.target.checked);
	};

	const asyncScriptOnLoad = () => {
		console.log('scriptLoad - reCaptcha Ref-');
	};

	// Edit mode
	useEffect(() => {
		if (editMode && values.id) {
			getSetLocations(values.state_id);
		}
	}, [editMode, values.id]);

	const { people_under_5, people_between_5_to_9, people_between_10_to_15, people_above_16 } = values;
	useEffect(() => {
		const totalValue =
			Math.abs(people_under_5) +
			Math.abs(people_between_5_to_9) +
			Math.abs(people_between_10_to_15) +
			Math.abs(people_above_16);

		setFieldValue('total_people', totalValue);
	}, [people_above_16, people_under_5, people_between_5_to_9, people_between_10_to_15]);

	return (
		<>
			<div>
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Your Details</p>
				</div>
				<div className="nkbf-my-8">
					<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikSelect
								className="nkbf-m-0 nkbf-w-full"
								labelId="stateSelect"
								label="State"
								options={selectionItems.states}
								selectId="stateSelect"
								customHandleChange={handleStateChange}
								name="state_id"
							/>
						</div>
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikSelect
								className="nkbf-m-0 nkbf-w-full"
								labelId="locationSelect"
								label="Centre Location"
								options={locations}
								selectId="locationSelect"
								name="location_id"
							/>
						</div>
					</div>
				</div>
				<div className="nkbf-flex nkbf-flex-col nkbf-p-8">
					<div className="nkbf-my-8">
						<FormikRadioButton label="Booking For" name="booking_for" options={bookingGroup} />
					</div>
					<FormikTextField
						label={values.booking_for === 1 ? 'School Name' : 'Group Name'}
						name="booking_name"
						variant="outlined"
						className="nkbf-my-8"
					/>
				</div>
			</div>

			<div className="nkbf-mt-8">
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Booking</p>
				</div>
				<div className="nkbf-my-8">
					<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikSelect
								className="nkbf-m-0 nkbf-w-full"
								labelId="bookingTypeSelect"
								label="Booking Type"
								options={selectionItems.bookingTypes}
								selectId="bookingTypeSelect"
								customHandleChange={handleBookingTypeChange}
								name="booking_type_id"
							/>
						</div>
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikSelect
								className="nkbf-m-0 nkbf-w-full"
								labelId="bookingDurationSelect"
								label="Booking Duration"
								options={selectionItems.bookingDurations}
								selectId="bookingDurationSelect"
								name="booking_duration_id"
							/>
						</div>
					</div>
					<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikPicker
								label="Preferred Booking Start Date"
								name="booking_start_date"
								dateTime={false}
								inputVariant="outlined"
								className="nkbf-m-0 nkbf-w-full"
							/>
						</div>
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikTime
								className="nkbf-m-0 nkbf-w-full"
								name="lesson_start_time"
								label="Preferred Lesson Start Time"
								ampm
								inputVariant="outlined"
							/>
						</div>
					</div>
					<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
						<div className="nkbf-w-1/2 nkbf-m-4">
							{selectionItems.bookingSubTypes.length > 0 && (
								<div className="nkbf-my-8">
									<FormikSelect
										className="nkbf-m-0 nkbf-w-full"
										labelId="bookingSubTypeSelect"
										label={`${subTypeLabel} Type`}
										options={selectionItems.bookingSubTypes}
										selectId="bookingSubTypeSelect"
										name="booking_sub_type_id"
									/>
								</div>
							)}
						</div>
						<div className="nkbf-w-1/2 nkbf-m-4">
							{(values.booking_type_id === 1 ||
								values.booking_type_id === 2 ||
								values.booking_type_id === 3) && (
								<div className="nkbf-my-8">
									<FormikSelect
										className="nkbf-m-0 nkbf-w-full"
										labelId="lessonDurationSelect"
										label="Lesson Duration"
										options={selectionItems.lessonDurations}
										selectId="lessonDurationSelect"
										name="lesson_duration_id"
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="nkbf-mt-8">
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Responsible Person at Booking</p>
				</div>
				<div className="nkbf-my-8">
					<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikTextField
								label="First Name"
								name="first_name"
								variant="outlined"
								className="nkbf-m-0 nkbf-w-full"
							/>
						</div>
						<div className="nkbf-w-1/2 nkbf-m-4">
							<FormikTextField
								label="Last Name"
								name="last_name"
								variant="outlined"
								className="nkbf-m-0 nkbf-w-full"
							/>
						</div>
					</div>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
					<div className="nkbf-w-1/2 nkbf-m-4">
						<FormikTextField
							label="School Email"
							name="email"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
					<div className="nkbf-w-1/2 nkbf-m-4">
						<FormikTextField
							label="Phone"
							name="mobile"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>
			</div>
			{values.extra_facility_requirements.length > 0 && (
				<div className="nkbf-mt-8">
					<div className="nkbf-border-b-1 nkbf-w-full">
						<p className="nkbf-text-md nkbf-font-bold">Extra Facility Requirements</p>
					</div>
					<div className="nkbf-w-full nkbf-flex nkbf-flex-row nkbf-flex-wrap nkbf-my-8 nkbf-p-8 nkbf--m-4">
						<div className="nkbf-m-4 nkbf-w-1/2">
							<FormikCheckbox
								label="Facilities"
								options={values.extra_facility_requirements}
								name="extra_facility_requirements"
							/>
						</div>
						{values.extra_facility_requirements.length > 0 &&
							values.extra_facility_requirements.map((item, index) => {
								if (item.is_checked) {
									if (item.service_items.length > 0) {
										return (
											<div className="nkbf-m-4 nkbf-w-1/2">
												<FormikCheckbox
													label={`${item.name} Requirements`}
													options={item.service_items}
													name={`extra_facility_requirements.${index}.service_items`}
												/>
											</div>
										);
									}
									if (item.has_text_field) {
										return (
											<div className="nkbf-m-4 nkbf-w-1/2">
												<FormikTextField
													label={`${item.name} Required`}
													name={`extra_facility_requirements.${index}.value`}
													variant="outlined"
													className="nkbf-m-0 nkbf-w-full"
												/>
											</div>
										);
									}
								}
								return null;
							})}
					</div>
				</div>
			)}

			<div className="nkbf-mt-8">
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Approximate Attendance</p>
				</div>
				<div className="nkbf-w-full nkbf-flex nkbf-flex-row nkbf-my-8 nkbf-p-8 nkbf--m-4">
					<div className="nkbf-m-4 nkbf-w-1/2">
						<FormikTextField
							label="Number of people under the age of 5"
							name="people_under_5"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
					<div className="nkbf-m-4 nkbf-w-1/2 ">
						<FormikTextField
							label="Number of people between ages 5-9"
							name="people_between_5_to_9"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>
				<div className="nkbf-w-full nkbf-flex nkbf-flex-row nkbf-my-8 nkbf-p-8 nkbf--m-4">
					<div className="nkbf-m-4 nkbf-w-1/2">
						<FormikTextField
							label="Number of people between ages 10-15"
							name="people_between_10_to_15"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
					<div className="nkbf-m-4 nkbf-w-1/2">
						<FormikTextField
							label="Number of people 16 years and older"
							name="people_above_16"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>
				<div className="nkbf-w-full nkbf-flex nkbf-flex-row nkbf-my-8 nkbf-p-8 nkbf--m-4">
					<div className="nkbf-m-4 nkbf-w-1/2">
						<p className="nkbf-text-md nkbf-font-medium">Total number of people: {values.total_people}</p>
					</div>
				</div>
			</div>
			<div className="nkbf-mt-8">
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Relevant Medical Conditions</p>
				</div>
				<div className="nkbf-w-full nkbf-mt-4 nkbf-p-8">
					<FormikTextField
						label="Medical Conditions"
						name="medical_conditions"
						variant="outlined"
						className="nkbf-m-0 nkbf-w-full"
						multiline
						rows={5}
					/>
				</div>
			</div>
			<div className="nkbf-mt-8">
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Additional Information</p>
				</div>
				<div className="nkbf-w-full nkbf-mt-4 nkbf-p-8">
					<FormikTextField
						label="Additional Comments"
						name="additional_comments"
						variant="outlined"
						className="nkbf-m-0 nkbf-w-full"
						multiline
						rows={5}
					/>
				</div>
			</div>
			{!editMode && (
				<div className="nkbf-mt-8">
					<div className="nkbf-border-b-1 nkbf-w-full">
						<p className="nkbf-text-md nkbf-font-bold">Terms & Conditions</p>
					</div>
					<div className="nkbf-w-full nkbf-mt-4 nkbf-p-8 nkbf-border-1 nkbf-h-256 nkbf-overflow-scroll alignment">
						<h1 className="nkbf-text-lg nkbf-font-semibold"> CONDITIONS OF HIRE</h1>
						<ol className="numbered-list">
							<li>
								APPLICATIONS The usual procedure is for all applications for hire to be made in writing.
								The Management, acting by its Centre Manager or other authorised official will supply
								contract forms for completion and signature by the hirer.
							</li>
							<li>
								CORRESPONDENCE All correspondence should be addressed to the Centre Manager at the
								address shown on the Hire Agreement.
							</li>
							<li>
								VENUE All correspondence, posters, publicity advertising events for which this facility
								is hired should identify by whom the event is organized, and should not give the
								impression that the event has been organized by the management, or the owner of the
								premises.
							</li>
							<li>
								CHARGES An additional fee is payable by the hirer for each hour or part of an hour that
								the said facilities are retained by the hirer from the expiry of the contractual period
								until the hirer has returned control thereof to the Management, ready for the use of a
								subsequent hirer. The additional fee is to be calculated pro-rata to the total fees paid
								or payable for the contractual period to the nearest complete hour and shall be paid to
								the Management on demand. If a fee per head is charged an additional fee is payable if
								more people attend for the booking than originally invoiced for. The additional fee
								shall be paid to the Management on demand. You will be charged at a minimum of the
								confirmed participants as well as any additional attendants.
							</li>
							<li>
								PAYMENT (a) Regular Bookings: On return of the signed contract, the Hirer shall enclose
								either: The full amount due or the first month's payment (as appropriate). Where
								payments are to be made on a monthly basis these shall be paid monthly in advance for
								the duration of the booking. (b) Special Bookings: On return of the signed contract, the
								Hirer must enclose a deposit of 25% of the basic hire charge as shown on the front page.
								The Hirer will pay the balance 14 days in advance of the event. The hirer will be
								invoiced after the event for any additional costs not specifically mentioned in the said
								contract at the request of the Hirer or additional facilities to be provided. The rights
								of the Hirer under this contract are conditional upon adherence to the payment terms set
								above.
							</li>
							<li>
								CANCELLATION (a) Regular Bookings: In the case of a cancellation of a block booking, the
								Hirer shall pay to the Management the full amount of the basic hire charge together with
								any other losses whatsoever incurred by the management. (b) Special Bookings: In the
								case of a cancellation of a special booking, the Hirer will forfeit the said deposit and
								if the hirer cancels the hiring within two months before the booking they will also be
								liable to pay the balance of the hire charge. (c) By The Management: The Management
								reserve the right to cancel any booking in the event of any unforeseen circumstances
								occurring prior to the booking or if in the opinion of the Management the holding of the
								event or its general nature or the entertainment or facilities to be provided at the
								event for which the premises have been hired would or might contravene any statute,
								order, regulation, rule of law or requirement of any Public or Local Authority or owner
								of the premises in which event the Management shall return to the Hirer any deposit
								paid. (d) Poor Weather: In the case of poor weather or lightning the management may
								decide to cancel an outside event on the grounds of safety. The management will
								determine when this is necessary and their decision is final. An alternate date will be
								offered at a mutually convenient time. If an alternative date cannot be found then a
								Credit Note to the value of 50% of the full hire fee will be issued to the hirer.
							</li>
							<li>
								ADMISSION The Management reserves the right at its absolute discretion to refuse the
								admission of, or to evict any person from, the facility. Admission by complimentary
								tickets is not permitted except with previous written permission of the Management.
								8.RIGHT TO RE-ALLOCATE If the Management are of the opinion that the Hirer is not making
								complete use of the said facilities, the Management reserves the right to re-allocate
								any unused parts thereof. 9.RULES & REGULATIONS FOR USERS The Hirer is responsible for
								ensuring that all persons and parties under his control, who are admitted to the
								facility, conform in all respects to the rules & regulations of the facility. A copy of
								the said rules & regulations is available at the facility for inspection. The hirer is
								also responsible for ensuring that all persons and parties under his control follow any
								instructions provided to them by facility staff during the event.
							</li>
							<li>
								EMERGENCY PROCEDURES & FIRST AID Should an emergency situation arise the centre
								management will initiate the centre’s Emergency Action Plan and take control of the
								event. The hirer must assist the centre management in any way possible to ensure the
								safety of all persons or parties present at the event. The centre management will
								provide enough suitably qualified First Aiders and Lifeguards to manage any emergency
								situations such as, Evacuations, Drowning, Major or Minor injuries etc. A summary of the
								Emergency Action Plan is attached to this contract.
							</li>
							<li>
								INDEMNITY (a) The use of the facility is at the Hirer's risk and the hirer shall
								indemnify the management against all liability incurred towards any third party or
								parties arising out of or incidental to the hire of facilities or equipment and due to
								the negligence or default of the hirer or persons or parties under his control. The
								Hirer shall, if required by the management, adequately insure with an insurance company
								approved by the management against the foregoing, and produce evidence thereof on
								demand. (b) Neither the Management, its officers, employees or agents shall be
								responsible for any loss or damage whether direct, indirect or consequential to any
								property suffered or sustained at the facility unless such loss or damage occurs as a
								result of the defective state of repair of the premises or the equipment at the
								facility.
							</li>
							<li>
								STRUCTURAL ALTERATIONS The Hirer shall not carry out any alterations to the building,
								nor shall he fix or cause to be fixed, any apparatus, equipment, notice or decoration
								without the previous written permission of the Management. 13.DAMAGE The Hirer agrees to
								pay the management on demand the cost of repairing or making good any loss or damage
								(fair wear and tear excepted) arising out of or incidental to the hiring. 14.ANIMALS No
								dogs or any other animals will be admitted to the facility except guide dogs for the
								blind and the person exercising control of a dog shall comply with any instructions
								given by or on behalf of the Management.
							</li>
							<li>
								SUPERVISION The Management reserves the right to staff and supervise all functions but
								special arrangements can be made with the Management in order that the Hirer can
								introduce his own staff, which shall be included in the said contract between the
								management and the Hirer. 16.BROADCASTING No Hirer shall grant sound or television
								broadcasting or filming rights without the prior written conditional consent of the
								Management. 17.CAPACITY The maximum number of people admitted to any function in the
								facility shall be based on the said facilities hired but must be at the absolute
								discretion of the management. Maximum numbers for an event will be declared to the hirer
								in writing depending upon the nature of the event, the area being used and the
								conditions applying to that event.
							</li>
							<li>
								STRUCTURAL ALTERATIONS The Hirer shall not carry out any alterations to the building,
								nor shall he fix or cause to be fixed, any apparatus, equipment, notice or decoration
								without the previous written permission of the Management.
							</li>
							<li>
								DAMAGE The Hirer agrees to pay the management on demand the cost of repairing or making
								good any loss or damage (fair wear and tear excepted) arising out of or incidental to
								the hiring.
							</li>
							<li>
								ANIMALS No dogs or any other animals will be admitted to the facility except guide dogs
								for the blind and the person exercising control of a dog shall comply with any
								instructions given by or on behalf of the Management.
							</li>
							<li>
								SUPERVISION The Management reserves the right to staff and supervise all functions but
								special arrangements can be made with the Management in order that the Hirer can
								introduce his own staff, which shall be included in the said contract between the
								management and the Hirer.
							</li>
							<li>
								BROADCASTING No Hirer shall grant sound or television broadcasting or filming rights
								without the prior written conditional consent of the Management.
							</li>
							<li>
								CAPACITY The maximum number of people admitted to any function in the facility shall be
								based on the said facilities hired but must be at the absolute discretion of the
								management. Maximum numbers for an event will be declared to the hirer in writing
								depending upon the nature of the event, the area being used and the conditions applying
								to that event.
							</li>
							<li>
								CATERING All catering arrangements must conform to the prevailing arrangements in
								operation at the facility at the time of booking unless otherwise arranged with the
								Management.
							</li>
							<li>
								SUB-LETTING The hirer shall not sub-let any part of the centre for any purpose
								whatsoever without the previous written consent of the Manager.
							</li>
							<li>
								ADVERTISING The hirer undertakes to submit to the Manager for approval the proposed
								programme and all posters and bills previous to advertising the event or booking and in
								any case not less than 14 days before the event or booking.
							</li>
						</ol>
					</div>
					<div className="nkbf-mt-8">
						<FormControlLabel
							control={
								<Checkbox
									checked={termsCheckbox}
									onChange={handleTermChange}
									name="termsCheck"
									color="primary"
								/>
							}
							label="I accept the Terms and Conditions"
						/>
					</div>
				</div>
			)}

			{!editMode && (
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
					<ReCAPTCHA
						style={{ display: 'inline-block' }}
						theme="light"
						sitekey={capchaKey}
						onChange={handleCapchaChange}
						asyncScriptOnLoad={asyncScriptOnLoad}
						ref={captchaRef}
					/>
				</div>
			)}

			<Button
				variant="contained"
				type="submit"
				style={{ marginTop: 20, width: 120 }}
				disabled={editMode ? isSubmitting : isSubmitting || !termsCheckbox || !capchaValue}
				color="primary"
			>
				{editMode ? 'Update' : 'Submit'}
			</Button>
		</>
	);
}
