import axios from 'axios';

export default function generateSchoolCrud(toApiUrl) {
	const SUBMIT_FORM_URL = toApiUrl('/gym-agreement');
	const LOCATION_URL = id => toApiUrl(`/locations/${id}`);
	const CAPTCHA_URL = toApiUrl('/captcha');

	function submitForm(payload) {
		return axios.post(SUBMIT_FORM_URL, payload);
	}

	function getLocations(id) {
		return axios.get(LOCATION_URL(id));
	}

	function checkCaptcha(payload) {
		return axios.post(CAPTCHA_URL, payload);
	}

	return {
		submitForm,
		getLocations,
		checkCaptcha
	};
}
