import { Formik, Form } from 'formik';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { GymForm, formValues, validationGym, generateGymCrud } from './lib/GymForm';

import { useNavigate } from 'react-router-dom';

const toApiUrl = pathname => `${process.env.REACT_APP_PATH_NAME}${pathname}`;
const capchaKey = process.env.REACT_APP_FORM_CAPTCHA_KEY;

export default function Gym() {
	const [messageState, setMessageState] = useState({
		message: '',
		open: false,
		severity: 'success'
	});
	const navigate = useNavigate();
	const { submitForm } = generateGymCrud(toApiUrl);

	const handleMessageClose = () => setMessageState({ ...messageState, open: false });

	const handleSubmit = async values => {
		try {
			const response = await submitForm(values);
			if (response) {
				setMessageState({
					open: true,
					severity: 'success',
					message: 'Form Submitted'
				});

				const query = new URLSearchParams(window.location.search);

				setTimeout(() => navigate(`/gymsuccess?location=${query.get('location')}`), 2000);
			}
		} catch (error) {
			setMessageState({
				open: true,
				severity: 'error',
				message: `There is an error: ${error.response?.data.message || error.message}`
			});
		}
	};
	return (
		<div className="min-h-screen flex flex-col">
			<div className="h-64 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<img src="/logo-white.png" width={150} height={30} alt="my-logo" />
			</div>
			<div className="h-full flex-grow flex flex-col justify-center items-center ">
				<main className="p-8 flex flex-col items-center w-full mt-32">
					<p className="text-xl font-semibold" style={{ textAlign: 'center' }}>
						Unsupervised Gym Access Induction
					</p>
					<div className="p-8 w-3/4 flex flex-col items-center mt-16" style={{ padding: '10px 50px 10px 50px' }}>
						<Formik initialValues={formValues} validationSchema={validationGym} onSubmit={handleSubmit}>
							<Form>
								<GymForm toApiUrl={toApiUrl} capchaKey={capchaKey} />
							</Form>
						</Formik>
						<Snackbar open={messageState.open} autoHideDuration={6000} onClose={handleMessageClose}>
							<Alert onClose={handleMessageClose} severity={messageState.severity}>
								{messageState.message}
							</Alert>
						</Snackbar>
					</div>
				</main>
			</div>
			<div className="h-32 mt-32 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<a
					href="https://bluefit.com.au"
					target="_blank"
					rel="noreferrer"
					className="text-white hover:text-blue-400"
				>
					BlueFit.com.au
				</a>
			</div>
		</div>
	);
}
