import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

Success.propTypes = {};

function Success() {
	useEffect(() => {
		setTimeout(() => {
			const query = new URLSearchParams(window.location.search);
			window.location.href = `/gym?location=${query.get('location')}`;
		}, 7000);
	}, []);
	return (
		<div className="min-h-screen flex flex-col">
			<div className="h-64 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<img src="/logo-white.png" width={150} height={30} alt="my-logo" />
			</div>
			<div className="flex-grow w-full flex flex-col justify-center items-center" style={{ textAlign: 'center' }}>
				<p className="text-blue-700 font-semibold text-lg items-center">
					Thank you for submitting your induction. Please see reception to collect your fob.
				</p>
			</div>
			<div className="h-32 mt-32 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<a
					href="https://bluefit.com.au"
					target="_blank"
					rel="noreferrer"
					className="text-white hover:text-blue-400"
				>
					BlueFit.com.au
				</a>
			</div>
		</div>
	);
}

export default Success;
