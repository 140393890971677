/* eslint-disable camelcase */
import { useState, useEffect, useRef, createRef } from 'react';
import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { FormikTextField, FormikSelect } from '../formik-material';
import PropTypes from 'prop-types';
import generateVoucherCrud from './Voucher.crud';

VoucherForm.propTypes = {
	toApiUrl: PropTypes.func,
	editMode: PropTypes.bool,
	capchaKey: PropTypes.string
};

export default function VoucherForm({ toApiUrl, editMode = false, capchaKey }) {
	const captchaRef = createRef();

	const [termsCheckbox, setTermsCheckbox] = useState(true);
	const [capchaValue, setChachaValue] = useState(false);

	const { values, setFieldValue, isSubmitting } = useFormikContext();

	const [location, setLocation] = useState(undefined);

	const [locationItems, setLocationItem] = useState([]);

	const { getLocations, checkCaptcha } = generateVoucherCrud(toApiUrl);

	useEffect(() => {
		document.title = '24/7 Gym Agreement';
	}, []);

	useEffect(() => {
		async function getData() {
			try {
				const query = new URLSearchParams(window.location.search);
				getSetLocations(query.get('department'));
				setFieldValue('department_id', query.get('department'));
			} catch (error) {}
		}

		getData();
	}, []);

	const getSetLocations = async id => {
		try {
			const response = await getLocations(id);
			if (response) {
				setLocationItem(response.data);
			}
		} catch (error) {}
	};

	const handleTermChange = e => {
		setTermsCheckbox(e.target.checked);
		setFieldValue('subscribed', e.target.checked);
	};

	const handleCapchaChange = async value => {
		try {
			const captchaResponse = await checkCaptcha({ response: value });
			if (captchaResponse.data.success) {
				setChachaValue(true);
				setFieldValue('subscribed', true);
			}
		} catch (e) {
			captchaRef.current.reset();
		}
	};

	const asyncScriptOnLoad = () => {
		console.log('scriptLoad - reCaptcha Ref-');
	};

	const handleLocationChange = e => {
		setFieldValue('location_id', e);
		setLocation(e);
	};

	return (
		<>
			<div className="nkbf-mt-8 voucher-form">
				{/* <div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Responsible Person at Booking</p>
				</div> */}
				<div className="nkbf-m-4">
					<h1 style={{ fontWeight: 800, fontSize: 16 }}>Your BlueFit Swimming Location *</h1>
					<FormikSelect
						className="nkbf-m-0 dropdown-width"
						labelId="locationSelect"
						label="Please Selected"
						options={locationItems}
						selectId="locationSelect"
						customHandleChange={handleLocationChange}
						name="location_id"
					/>
				</div>

				<div className="nkbf-m-4">
					<h1 style={{ fontWeight: 800, fontSize: 16 }}>Voucher Code *</h1>
					<FormikTextField
						label="Voucher Code"
						name="voucher_code"
						variant="outlined"
						className="nkbf-m-0 nkbf-w-full"
					/>
				</div>
				<div className="nkbf-m-4">
					<h1 style={{ fontWeight: 800, fontSize: 16 }}>Name *</h1>
					<FormikTextField
						label="First Name"
						name="first_name"
						variant="outlined"
						className="nkbf-m-0 nkbf-w-full"
					/>
				</div>
				<div className="nkbf-m-4">
					<h1 style={{ fontWeight: 800, fontSize: 16 }}>&nbsp;</h1>
					<FormikTextField
						label="Last Name"
						name="last_name"
						variant="outlined"
						className="nkbf-m-0 nkbf-w-full"
					/>
				</div>

				<div className="nkbf-m-4">
					<h1 style={{ fontWeight: 800, fontSize: 16 }}>Email *</h1>
					<FormikTextField label="Email" name="email" variant="outlined" className="nkbf-m-0 nkbf-w-full" />
				</div>
				<div className="nkbf-m-4">
					<h1 style={{ fontWeight: 800, fontSize: 16 }}>Phone Number *</h1>
					<FormikTextField
						label="Phone Number"
						name="phone"
						variant="outlined"
						className="nkbf-m-0 nkbf-w-full"
					/>
				</div>
			</div>
			{!editMode && (
				<div className="nkbf-mt-20">
					<div className="nkbf-mt-8">
						<FormControlLabel
							control={
								<Checkbox
									checked={termsCheckbox}
									onChange={handleTermChange}
									name="termsCheck"
									color="primary"
								/>
							}
							label="By submitting this voucher redemption form, I am subscribing to BlueFit's Marketing newsletter."
						/>
					</div>
				</div>
			)}
			<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
				<ReCAPTCHA
					style={{ display: 'inline-block' }}
					theme="light"
					sitekey={capchaKey}
					onChange={handleCapchaChange}
					asyncScriptOnLoad={asyncScriptOnLoad}
					ref={captchaRef}
				/>
			</div>

			<Button
				variant="contained"
				type="submit"
				style={{ marginTop: 20, width: 120 }}
				disabled={isSubmitting || !termsCheckbox || !capchaValue || !location}
				color="primary"
			>
				{editMode ? 'Update' : 'Submit'}
			</Button>
		</>
	);
}
