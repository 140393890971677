import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';

Success.propTypes = {};

function Success(props) {

	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1); 
	};

	return (
		<div className="min-h-screen flex flex-col">
			<div className="h-64 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<img src="/logo-white.png" width={150} height={30} alt="my-logo" />
			</div>
			<div className="flex-grow w-full flex flex-col justify-center items-center">
				<p className="text-blue-700 font-semibold text-lg">
					Thank you for submitting your request. You will receive an email confirmation shortly.
				</p>

				<Button variant="contained" type="submit" style={{ marginTop: 20, width: 120 }} color="primary"
					onClick={() => handleBack()}
				>
					back
				</Button>
			</div>
			<div className="h-32 mt-32 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<a
					href="https://bluefit.com.au"
					target="_blank"
					rel="noreferrer"
					className="text-white hover:text-blue-400"
				>
					BlueFit.com.au
				</a>
			</div>
		</div>
	);
}

export default Success;
