import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { fuseDark, skyBlue } from './colors';

// Create a theme instance.
const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: fuseDark[700]
		},
		secondary: {
			main: skyBlue[500]
		},
		background: {
			paper: '#FFFFFF',
			default: '#F7F7F7'
		},
		error: red
	},
	status: {
		danger: 'orange'
	}
});

export default theme;
