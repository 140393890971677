/* eslint-disable camelcase */
import { useState, useEffect, useRef, createRef } from 'react';
import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import SignatureCanvas from 'react-signature-canvas';
import { Checkbox, FormControlLabel, Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormikTextField, FormikPicker } from '../formik-material';

import PropTypes from 'prop-types';
import generateGymCrud from './Gym.crud';

GymForm.propTypes = {
	toApiUrl: PropTypes.func,
	editMode: PropTypes.bool,
	capchaKey: PropTypes.string
};

export default function GymForm({ toApiUrl, editMode = false, capchaKey }) {
	const sigPad = useRef(null);
	const captchaRef = createRef();

	const [termsCheckbox, setTermsCheckbox] = useState(false);
	const [capchaValue, setChachaValue] = useState(false);
	const [signature, setSignature] = useState(false);

	const { values, setFieldValue, isSubmitting } = useFormikContext();

	const [location, setLocation] = useState(undefined);

	const [expanded, setExpanded] = useState(false);

	const { getLocations, checkCaptcha } = generateGymCrud(toApiUrl);

	useEffect(() => {
		document.title = 'Unsupervised Gym Access Induction Gym Agreement';
	}, []);

	useEffect(() => {
		async function getData() {
			try {
				const query = new URLSearchParams(window.location.search);
				getSetLocations(query.get('location'));
			} catch (error) {}
		}

		getData();
	}, []);

	const getSetLocations = async id => {
		try {
			const response = await getLocations(id);
			if (response) {
				setFieldValue('location_id', response.data.id);
				setLocation(response.data.id);
			}
		} catch (error) {}
	};

	const handleTermChange = e => {
		setTermsCheckbox(e.target.checked);
	};

	const handleCapchaChange = async value => {
		try {
			const captchaResponse = await checkCaptcha({ response: value });
			if (captchaResponse.data.success) {
				setChachaValue(true);
			}
		} catch (e) {
			captchaRef.current.reset();
		}
	};

	const asyncScriptOnLoad = () => {
		console.log('scriptLoad - reCaptcha Ref-');
	};

	const handleAccordionChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleEnd = () => {
		setFieldValue('signature', sigPad.current.toDataURL());
		setSignature(true);
	};

	return (
		<>
			<div>
				<div className="nkbf-border-b-1 nkbf-w-full" style={{ fontSize: 17 }}>
					<p>
						<div>
							In order to access our gym outside of staffed hours, you first need to complete your
							induction. Your safety is our priority, and we want to make sure your time in our centre
							meets all your expectations
						</div>
						<p>&nbsp;</p>
						<div>
							Once you complete this induction, approved members will receive confirmation of access
							within 3 business days. You’ll need to pick up your membership fob to access the facility
							out of hours.
						</div>
					</p>
					<p>&nbsp;</p>
					<p>
						<span>Three step process:</span>
					</p>
					<p>&nbsp;</p>
					<ol className="numbered-list">
						<li>
							<span>Please watch the video below or read the transcript.</span>
						</li>
						<li>
							<span>
								Then, read and agree to our terms and conditions, and complete your details in our
								online form.
							</span>
						</li>
						<li>
							<span>
								Your access will then be activated within 3 business days. You will receive a text when
								your access is ready and you’ll be good to go.
							</span>
						</li>
					</ol>
					<p>&nbsp;</p>
				</div>
			</div>
			<div className="nkbf-mt-8">
				<p className="nkbf-text-md nkbf-font-bold">Induction Video</p>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8 embed-container">
					<iframe
						title="video"
						width="60%"
						height="400"
						src="https://player.vimeo.com/video/645123180"
						frameBorder="0"
						allow="autoplay; encrypted-media"
						allowFullScreen=""
					/>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
					<Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
						<AccordionSummary
							aria-controls="panel1bh-content"
							id="panel1bh-header"
							expandIcon={<ExpandMoreIcon />}
						>
							<p className="nkbf-text-md nkbf-font-bold">Transcript of the Video</p>
						</AccordionSummary>
						<div
							className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf-h-24"
							style={{ background: '#F7F7F7' }}
						>
							&nbsp;
						</div>
						<AccordionDetails>
							<div className="nkbf-w-full nkbf-mt-8 nkbf-p-8 nkbf-h-128 nkbf-overflow-scroll">
								<p>
									<span>
										Welcome to BlueFit Health Club, we are excited to offer access to the facility
										outside of staffed hours. Before you begin unsupervised training, its important
										you take note of our conditions of use.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										To access the facility outside of staffed hours you will need to scan your
										membership card or fob at the out of hours entrance. This can be identified by
										the out of hours sign located on the doo.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										Your experience while in the facility is paramount and we want to ensure you
										train safe at all times.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										Inside the health club you will notice a safety board. This board includes a
										number of key features:&nbsp;
									</span>
									<span>
										<ul>
											<li>
												1. Security phone, which gives you direct access to our offsite security
												team
											</li>
											<li>
												2. Duress lanyard which we strongly recommend you carry around your neck
												or close by at all times. When activated this alarm will alert our
												security team immediately{' '}
											</li>
											<li>
												3. First Aid Kit and Defibrillator to use in case of a medical emergency
											</li>
											<li>4. Emergency contacts if you require further assistance</li>
										</ul>
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										While using the facility please take note of our emergency exits. Our evacuation
										map can be found adjacent to the safety board.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										Our standard health and hygiene rules apply at all times, please bring a towel
										with you when you train and wipe equipment down after use. We do not recommend
										training during unsupervised hours if you are unwell or have a medical
										condition, unless you have received clearance from your doctor.&nbsp;&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										When working out during unsupervised hours please only use equipment that you
										have been shown how to use properly or are familiar with. We do not recommend
										lifting heavy weights unless you have a spotter present.
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										We have video surveillance throughout the facility which is monitored by our
										security team. Please note allowing another person to enter the facility using
										your membership access or tailgating is a breach of the terms and conditions and
										may result in the cancellation of your membership.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										Outstanding balances or issues with your account will automatically stop access
										to the facility out of hours. Please see our reception team during staffed hours
										if you encounter any issues with your access.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										If the door does not work when you try to scan in, this indicates there is an
										issue with your account such as an outstanding balance. Do not try to force the
										door open or tailgate as you are liable for any damages to the property. Simply
										see our reception team or call the facility during staffed hours to rectify any
										account issues.&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
								<p>
									<span>
										We look forward to seeing you train at your facility. Remember there are plenty
										of workouts available via the BlueFit app, so even during our unstaffed hours
										we're here to motivate and guide you!&nbsp;
									</span>
								</p>
								<p>&nbsp;</p>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>

				{location === 57 && (
					<div>
						<p className="text-xl nkbf-font-bold nkbf-mt-8 nkbf-p-8" style={{ textAlign: 'center' }}>
							Unsupervised Pool Access Induction
						</p>
						<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
							<Accordion expanded className="nkbf-w-full">
								<AccordionSummary>
									<div className="nkbf-block nkbf-flex-row">
										<p className="nkbf-text-md nkbf-font-bold">Unsupervised Pool Induction Terms</p>
										<p>Please click on the links below to view the terms</p>
									</div>
								</AccordionSummary>

								<AccordionDetails>
									<div
										style={{ height: 'auto' }}
										className="nkbf-w-full nkbf-mt-8 nkbf-p-8 nkbf-h-128 nkbf-border-1 nkbf-overflow-scroll nkbf-w-full"
									>
										<ol>
											<li>
												I have read and understood &nbsp;
												<a
													style={{ color: 'blue' }}
													target="_blank"
													href="https://clubbridgefield.com.au/terms-and-conditions#terms"
													rel="noreferrer"
												>
													the facility terms and conditions.
												</a>
											</li>
											<li>
												I have read and understood &nbsp;
												<a
													style={{ color: 'blue' }}
													target="_blank"
													href="https://clubbridgefield.com.au/terms-and-conditions#poolrules"
													rel="noreferrer"
												>
													the pool rules.
												</a>
											</li>
										</ol>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</div>
				)}

				{location === 35 && (
					<div>
						<p className="text-xl nkbf-font-bold nkbf-mt-8 nkbf-p-8" style={{ textAlign: 'center' }}>
							Unsupervised Pool Access Induction
						</p>
						<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
							<Accordion expanded className="nkbf-w-full">
								<AccordionSummary>
									<p className="nkbf-text-md nkbf-font-bold">Unsupervised Pool Induction Terms</p>
								</AccordionSummary>

								<AccordionDetails>
									<div
										style={{ height: 275 }}
										className="nkbf-w-full nkbf-mt-8 nkbf-p-8 nkbf-h-128 nkbf-border-1 nkbf-overflow-scroll nkbf-w-full"
									>
										<ol>
											<li>I have read and understood the facility terms and conditions.</li>
											<li>I have read and understood the pool rules.</li>
										</ol>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</div>
				)}

				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
					<Accordion expanded>
						<AccordionSummary>
							<p className="nkbf-text-md nkbf-font-bold">Terms and Conditions Details</p>
						</AccordionSummary>

						<AccordionDetails>
							<div
								style={{ height: 300 }}
								className="nkbf-w-full nkbf-mt-8 nkbf-p-8 nkbf-h-128 nkbf-border-1 nkbf-overflow-scroll"
							>
								<ol>
									<li>
										<div>Below are some important details relating to your membership: </div>
										<br />
										<div>Supervision by Staff</div>
										<div>
											I understand that outside of stipulated staffed hours the centre will be
											unstaffed and no staff will be onsite to offer any assistance or exercise
											direction or first aid treatment.{' '}
										</div>
										<div>
											I acknowledge that during unstaffed times there will be no monitoring of the
											safety or equipment of facilities, should an item fail, be damaged or some
											other risk eventuate.
										</div>
										<br />
										<div>Orientation</div>
										<div>I have been shown how to access the centre during unstaffed hours.</div>
										<div>
											I have been advised of fire exit locations to be used in the event of an
											emergency requiring evacuation.
										</div>
										<div>I have been shown the location of duress pendants and alarms.</div>
										<div>
											I understand that I must wear an alarmed duress pendant at all times when I
											am in the centre alone.
										</div>
										<div>
											I accept failure to return a duress pendant to the designated area will
											result in a $100 replacement fee being charged.
										</div>
										<div>
											I accept vandalism made to the pendant including but not limited to writing
											on or engraving will result in a $100 replacement fee I understand that the
											duress pendant or alarm shall only be activated in the event of an emergency
											and for any false activation I will be charged the associated callout fee by
											security and emergency services.
										</div>
										<div>
											I have been shown the location of the first aid kit, fire extinguishers,
											emergency exits and assembly area.
										</div>
										<div>
											I have been shown and understand that I am required to use the safety
											features on exercise equipment during unstaffed hours.
										</div>
										<div>
											I have been advised and accept that when using heavy weights that I should
											ensure a spotter is available. Health I agree by signing this agreement I
											represent that I am in good physical condition and have no reason or
											impairment that might prevent me from using the equipment at the Centre.
										</div>
										<div>I accept I repeat this representation each time I use the facility.</div>
										<div>
											I acknowledge BlueFit strongly advises to take expert advice prior to
											commencing membership or prior to commencing a new exercise program. I will
											monitor my physical condition at all times and exercise at a level that is
											appropriate to my health and any medical advice I have obtained. BlueFit
											staff are not medically trained and cannot assess whether an individual is
											in a fit state to participate in physical activity.{' '}
										</div>
										<br />
										<div>Use of Facility and Equipment </div>
										<div>
											I am aware that negligent damage or loss of my after-hours access fob
											resulting in the requirement for a replacement will incur a $60 replacement
											fee.
										</div>
										<div>
											I understand and accept that I must return my after-hours access fob upon
											cancelling my membership. If I cannot provide my access fob upon cancelling
											I will be charged a $60 replacement fee before my membership cancellation
											can occur.
											<div>
												I am aware that a towel is required to be used at each workout, no denim
												clothing is to be worn in the gym and that appropriate footwear is
												required. I accept if I enter the gym without a towel, wearing denim or
												without appropriate footwear I will be under CCTV recording and this
												could cause my Unsupervised Gym Access access to be ceased.
											</div>
											<div>
												I accept the practice of not sharing equipment with other gym users is
												not considered fair or reasonable behaviour.
											</div>
											<div>
												{' '}
												I accept as a BlueFit member I will show respect for all BlueFit members
												and equipment at all times. Failure to comply may result in the
												suspension or cancellation of my membership.
											</div>
											<div>
												I accept that I will be liable for replacement or repair charges for any
												damages I cause to equipment, fittings or other BlueFit property whilst
												I am in the centre.
											</div>
											<div>
												I am aware towels are to be placed on equipment when in use and
												disinfectant spray bottles and paper towels are to be used to wipe down
												equipment after use.
											</div>
											<div>
												I understand and accept I am not permitted to allow entry to the centre
												to any person during unstaffed hours under any circumstance.
											</div>
											<div>
												I accept I will be charged $250 non-compliance fee and risk termination
												of my membership for allowing unauthorised entry to the centre.
											</div>
											<div>
												I understand that I must only access the gym and change room facilities
												during unstaffed times. Any attempted or actual access of any other
												areas including but not limited to: the gym floor staff workstation,
												group fitness studio or pool hall, will result in a $250 non-compliance
												fee and risk termination of my membership.
											</div>
											<div>
												I accept that my membership may be suspended or cancelled if I have
												membership fees owing. I may bring a guest to the centre (where
												applicable) during regular staffed hours for which a casual entry fee
												will be paid. I understand that casual visits are not permitted during
												unstaffed hours.
											</div>
											<div>
												I understand that the disabled access bathroom will become a unisex, all
												access, single occupancy bathroom (only available to one person at a
												time) during unstaffed hours. The BlueFit reserves the right to refuse
												entry to any member and has the right to terminate entitlement of use
												without warning if any member fails to comply with BlueFit rules of
												conduct, gym etiquette or displays inappropriate behaviour.
											</div>
											<br />
											<div>Video Recording</div>I understand the centre is under Unsupervised Gym
											Access video recording. I acknowledge and accept I will be subject to video
											recording within the facility (with the exception of bathrooms and change
											rooms).
										</div>
										<br />
										<div>Warning of potential harm and assumption of risk</div>
										<div>
											I acknowledge and am aware that whilst all reasonable care and skill may be
											taken by BlueFit to ensure safe equipment, facilities and provision of
											service, there are some significant and inherent risks involved in the use
											of the facilities and exercise equipment. Injuries or illnesses may be
											serious and include, but are not limited to, muscular strains, ligament and
											tendon sprains, bone fractures, neck and spinal damage, heart-attack,
											stroke, permanent disability, death or psychological illness. I understand
											these injuries or illnesses may impact my future abilities to earn a living,
											engage in other business, social and recreational activities and generally
											enjoy life.{' '}
										</div>
										<div>These injuries may occur from:</div>
										<ul>
											<li>a. Slipping on a wet floor</li>
											<li>b. Tripping on equipment on the floor</li>
											<li>c. Being struck by weights</li>
											<li>d. Colliding with equipment or other members</li>
											<li>e. Engaging in strenuous activities</li>
											<li>f. Incorrect use of equipment or centre facilities</li>
										</ul>
										<div>
											I acknowledge and agree that the above mentioned injuries and potential
											causes of injuries are not exhaustive, and there are other unknown or
											anticipated risks that may result in injury, illness or death.
										</div>
										<div>
											I acknowledge that any such injury may result not only from my actions but
											from the action, omission or negligence of other gym users.
										</div>
										<div>
											I understand and appreciate the risks from the use of the facilities and
											equipment, agree that I am participating voluntarily and agree to assume
											responsibility for those risks.
										</div>
										<br />
										<div>Waiver of Negligence</div>
										<div>
											In consideration of the risks and potential for serious injury, and to the
											full extent permitted by law, I hereby waive on behalf of myself, my spouse,
											heirs and executors hereafter, liability against BlueFit, it’s employees,
											volunteers, agents from any and all claims in the event of injury, illness
											or adverse change in my medical condition or state of health (whether
											permanent or temporary) which may occur directly or indirectly from my use
											of the facilities or services provided, including but not limited to – use
											of equipment (whether supervised or not by BlueFit staff), change rooms,
											access ways, car park, and any advice or assistance provided by BlueFit
											employees, volunteers or agents.
										</div>
										<br />
										<div>Indemnification and Release</div>
										<div>
											I agree to indemnify, release and hold harmless BlueFit its employees,
											volunteers and agents from any and all liability for any costs that may
											occur, including but not limited to medical treatment, emergency
											transportation, legal costs, court costs or investigative costs, resulting
											from my use of the facility and services. I understand this includes any
											injury, illness or damage that arises as a result of the actions or
											inactions of other users of equipment, facilities or services and any
											activity conducted away from the facility.{' '}
										</div>
										<br />
										<div>Liability for Property</div>
										<div>
											I agree and accept BlueFit is not liable for my personal property that is
											damaged, lost or stolen whilst in or around the centre including but not
											limited to: a vehicle and its contents, any property left in lockers or any
											other area of the centre.
										</div>
										<br />
										<div>Agreement and Enforcement</div>
										<div>
											This document contains the Agreement, Terms and Conditions for use of the
											facilities and equipment during unstaffed times only and are in addition to
											the Terms and Conditions included in my Membership Agreement which shall
											continue to apply during unstaffed times. If part or all of any provision of
											this Agreement is illegal or unenforceable, it may be severed from this
											Agreement and the provisions that remain will continue in force. If BlueFit
											does not enforce any rights in this agreement for any reason, BlueFit does
											not waive its right to enforce it later. I agree by signing this Agreement I
											am bound by its terms.
										</div>
									</li>
								</ol>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-16 p-4-padding">
					<p className="nkbf-text-md nkbf-font-bold">Personal Details</p>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-4">
					<div className="nkbf-w-full nkbf-m-4">
						<FormikTextField
							label="First Name"
							name="first_name"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-4">
					<div className="nkbf-w-full nkbf-m-4">
						<FormikTextField
							label="Last Name"
							name="last_name"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>

				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-4">
					<div className="nkbf-w-full nkbf-m-4">
						<FormikTextField
							label="Email"
							name="email"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-4">
					<div className="nkbf-w-full nkbf-m-4">
						<FormikTextField
							label="Mobile"
							name="phone"
							variant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>

				<div className="nkbf-my-8 dob-field">
					<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-4">
						<FormikPicker
							label="Date of Birth"
							name="dob"
							dateTime={false}
							inputVariant="outlined"
							className="nkbf-m-0 nkbf-w-full"
						/>
					</div>
				</div>
			</div>
			{!editMode && (
				<div className="nkbf-mt-20">
					<div className="nkbf-border-b-1 nkbf-w-full">
						<p className="nkbf-text-md nkbf-font-bold">Terms & Conditions</p>
					</div>
					<div
						style={{ background: 'white' }}
						className="nkbf-w-full nkbf-mt-8 nkbf-border-1 nkbf-p-8 nkbf-h-32 nkbf-overflow-scroll"
					>
						<ol>
							<li>
								This document contains the Agreement, Terms and Conditions for use of the facilities and
								equipment during unstaffed times only and are in addition to the Terms and Conditions
								included in my Membership Agreement which shall continue to apply during unstaffed
								times. If part or all of any provision of this Agreement is illegal or unenforceable, it
								may be severed from this Agreement and the provisions that remain will continue in
								force. If BlueFit does not enforce any rights in this agreement for any reason, BlueFit
								does not waive its right to enforce it later. I agree by signing this Agreement I am
								bound by its terms.
							</li>
						</ol>
					</div>
					<div className="nkbf-mt-8">
						<FormControlLabel
							control={
								<Checkbox
									checked={termsCheckbox}
									onChange={handleTermChange}
									name="termsCheck"
									color="primary"
								/>
							}
							label="I accept the Terms and Conditions"
						/>
					</div>
				</div>
			)}
			<div className="nkbf-mt-8">
				<div className="nkbf-border-b-1 nkbf-w-full">
					<p className="nkbf-text-md nkbf-font-bold">Signature</p>
				</div>
				<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8">
					<div style={{ background: 'white' }} className="nkbf-w-1/2 nkbf-m-4 nkbf-border-1 mweb-signature">
						<SignatureCanvas
							name="signature"
							penColor="black"
							canvasProps={{ width: 'inherit', height: 'inherit', borderWidth: 1, className: 'sigPad' }}
							ref={sigPad}
							onEnd={handleEnd}
						/>
					</div>
				</div>
				<p>Please draw your signature here</p>
			</div>
			<div className="nkbf-flex nkbf-flex-row nkbf-w-full nkbf--m-4 nkbf-mt-8 nkbf-p-8">
				<ReCAPTCHA
					style={{ display: 'inline-block' }}
					theme="light"
					sitekey={capchaKey}
					onChange={handleCapchaChange}
					asyncScriptOnLoad={asyncScriptOnLoad}
					ref={captchaRef}
				/>
			</div>

			<Button
				variant="contained"
				type="submit"
				style={{ marginTop: 20, width: 120 }}
				disabled={
					editMode ? isSubmitting : isSubmitting || !termsCheckbox || !signature || !capchaValue || !location
				}
				color="primary"
			>
				{editMode ? 'Update' : 'Submit'}
			</Button>
		</>
	);
}
