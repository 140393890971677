import axios from 'axios';

export default function generateVoucherCrud(toApiUrl) {
	const SUBMIT_FORM_URL = toApiUrl('/promotion-vouchers');
	const LOCATION_URL = id => toApiUrl(`/department/${id}/locations`);
	const CAPTCHA_URL = toApiUrl('/captcha');

	function submitForm(payload) {
		return axios.post(SUBMIT_FORM_URL, payload);
	}

	function getLocations(id) {
		return axios.get(LOCATION_URL(id));
	}

	function checkCaptcha(payload) {
		return axios.post(CAPTCHA_URL, payload);
	}

	return {
		submitForm,
		getLocations,
		checkCaptcha
	};
}
