import axios from 'axios';

export default function generateSchoolCrud(toApiUrl) {
	const LOCATION_URL = id => toApiUrl(`/state/${id}/locations`);
	const STATE_URL = toApiUrl('/states');
	const BOOKING_TYPES_URL = toApiUrl('/booking-types');
	const BOOKING_SUB_TYPES_URL = id => toApiUrl(`/booking-type/${id}/sub-types`);
	const LESSON_DURATION_URL = toApiUrl('/lesson-duration');
	const BOOKING_DURATION_URL = toApiUrl('/booking-duration');
	const SERVICES_URL = id => toApiUrl(`/booking-type/${id}/services`);
	const SERVICE_ITEMS_URL = id => toApiUrl(`/service/${id}/items`);
	const SUBMIT_FORM_URL = toApiUrl('/school-bookings');
	const CAPTCHA_URL = toApiUrl('/captcha');

	function submitForm(payload) {
		return axios.post(SUBMIT_FORM_URL, payload);
	}

	function getLocations(id) {
		return axios.get(LOCATION_URL(id));
	}

	function getStates() {
		return axios.get(STATE_URL);
	}

	function getBookingTypes() {
		return axios.get(BOOKING_TYPES_URL);
	}

	function getBookingSubTypes(id) {
		return axios.get(BOOKING_SUB_TYPES_URL(id));
	}

	function getLessonDuration() {
		return axios.get(LESSON_DURATION_URL);
	}

	function getBookingDuration() {
		return axios.get(BOOKING_DURATION_URL);
	}

	function getServices(id) {
		return axios.get(SERVICES_URL(id));
	}

	function getServiceItems(id) {
		return axios.get(SERVICE_ITEMS_URL(id));
	}

	function checkCaptcha(payload) {
		return axios.post(CAPTCHA_URL, payload);
	}

	return {
		submitForm,
		getLocations,
		getStates,
		getBookingTypes,
		getBookingDuration,
		getBookingSubTypes,
		getServiceItems,
		getServices,
		getLessonDuration,
		checkCaptcha
	};
}
