import './lib/tailwind.css';

export default function Home() {
	return (
		<div className="min-h-screen flex flex-col">
			<div className="h-64 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<img src="/logo-white.png" width={150} height={30} alt="my-logo" />
			</div>
			<div className="h-full flex-grow p-4 flex flex-col justify-center items-center ">

				<main className="h-full p-8 flex flex-col justify-center items-center">
					<p className="text-lg font-semibold text-primary">Welcome to BlueFit Form</p>
				</main>
			</div>
			<div className="h-32 mt-32 w-full flex flex-row justify-center items-center bg-gradient-to-r from-[#0255A9] to-[#03AAEE]">
				<a
					href="https://bluefit.com.au"
					target="_blank"
					rel="noreferrer"
					className="text-white hover:text-blue-400"
				>
					BlueFit.com.au
				</a>
			</div>
		</div>
	);
}
