import * as yup from 'yup';

export const validationVoucher = yup.object().shape({
	location_id: yup.number().required('Required'),
	department_id: yup.number().required('Required'),
	first_name: yup.string().required('Required'),
	last_name: yup.string().required('Required'),
	email: yup.string().email().required('Required'),
	subscribed: yup.boolean().required('Required'),
	phone: yup.string().required('Please enter a valid phone number'),
	voucher_code: yup.string().required('This can be found on the back of the card')
});

export const formValues = {
	location_id: '',
	first_name: '',
	last_name: '',
	email: '',
	phone: ''
};
