/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DayjsUtils from '@date-io/dayjs';
import {
	InputBase,
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
	InputLabel,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormGroup,
	Switch,
	Button,
	Grid,
	FormLabel,
	Card,
	CardMedia,
	ButtonGroup,
	Typography,
	Checkbox,
	Collapse
} from '@material-ui/core';

import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { useField, useFormikContext, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
// import '../lib/styles/styles.scss';
import * as dayjs from 'dayjs';
import axios from 'axios';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@material-ui/lab/Autocomplete';

const incidentFormIcons = {
	near_miss: '/assets/images/incidents/icon-near-miss.svg',
	injury: '/assets/images/incidents/icon-injury.svg',
	incident_other: '/assets/images/incidents/icon -incident-other.svg',
	near_miss_guest: '/assets/images/incidents/icon-customer.svg',
	near_miss_staff: '/assets/images/incidents/icon-staff.svg',
	near_miss_osh: '/assets/images/incidents/icon-osh.svg',
	incident_other_staff: '/assets/images/incidents/icon-staff.svg',
	incident_other_osh: '/assets/images/incidents/icon-osh.svg',
	incident_other_guest: '/assets/images/incidents/icon-customer.svg',
	injury_guest: '/assets/images/incidents/icon-customer.svg',
	injury_staff: '/assets/images/incidents/icon-staff.svg',
	injury_osh: '/assets/images/incidents/icon-osh.svg'
};

const EmergencyFormIcons = {
	non_life_threatening: '/assets/images/emergency/warning.svg',
	life_threatening: '/assets/images/emergency/flame.svg',
	chemical_spill: '/assets/images/emergency/tube.svg',
	plant_room_shutdown: '/assets/images/emergency/battery.svg',
	other: '/assets/images/emergency/other.svg',
	power_outage: '/assets/images/emergency/power.svg',
	medical: '/assets/images/emergency/medical.svg',
	evacuation: '/assets/images/emergency/evacuation.svg',
	rescue: '/assets/images/emergency/rescue.svg',
	lightning: '/assets/images/emergency/lightning.svg'
};

const feedbackFormIcons = {
	'Positive Feedback': {
		icon: '/assets/images/feedback/feedback-positive.svg',
		description: 'Appraisals, positive feedback about the facility, programs etc.'
	},
	Suggestion: {
		icon: '/assets/images/feedback/feedback-suggestion.svg',
		description: 'Ideas for improvements, new class or other type of suggestions'
	},
	Complaint: {
		icon: '/assets/images/feedback/feedback-complaint.svg',
		description: 'Customer negative complaint about our facilities, staff etc.'
	},
	Member: {
		icon: '/assets/images/feedback/feedback-member.svg',
		description: 'All BlueFit membership holders (Health Club members and program students)'
	},
	Guest: {
		icon: '/assets/images/feedback/feedback-guest.svg',
		description: 'Guest / visitors, spectators, trade persons or carers - Non membership holders'
	},
	Staff: {
		icon: '/assets/images/feedback/feedback-staff.svg',
		description: 'All BlueFit staff'
	}
};
// Style used locally
export const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	button: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	margin: {
		margin: theme.spacing(1)
	},
	p8: {
		padding: theme.spacing(1)
	},
	full: {
		width: '100%',
		height: '100%'
	},
	flex: {
		display: 'flex'
	},
	formControl: {
		margin: `${theme.spacing(3)}px 0px`
	},
	paper: {
		height: 140,
		width: 100
	},
	customRadioContainer: {
		width: '255px',
		height: '290px',
		display: 'block',
		background: '#F9F9F9',
		marginBottom: '1rem',
		textAlign: 'center',
		boxShadow: '0px 3px 10px -2px hsla(150, 5%, 65%, 0.5)',
		position: 'relative',
		marginRight: '70px',
		padding: '20px 35px 40px 35px'
	},
	customFeedbackRadioContainer: {
		width: '100%',
		display: 'block',
		background: '#F9F9F9',
		marginBottom: '1rem',
		textAlign: 'center',
		position: 'relative',
		marginRight: '70px',
		padding: '20px 20px 20px 35px'
	},
	customRadio: {
		display: 'none'
	},
	radioIcon: {
		borderRadius: '50%',
		height: '100px',
		width: '100px',
		marginLeft: '20%',
		boxShadow: '-1px 8px 7px 3px hsl(150deg 5% 65% / 50%)',
		marginBottom: '20px'
	},
	feedbackRadioIcon: {
		borderRadius: '50%',
		boxShadow: '-1px 8px 7px 3px hsl(150deg 5% 65% / 50%)'
	},
	iconContainer: {
		textAlign: 'center',
		margin: 'auto'
	},
	radioDescription: {
		display: '-webkit-box',
		'-webkit-line-clamp': 5,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		width: '175px'
	},
	feedbackRadioDescription: {
		display: '-webkit-box',
		'-webkit-line-clamp': 5,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden'
	},
	border: {
		border: '1px solid'
	},
	customEmergencyRadio: {
		height: '100px',
		width: '100px',
		marginLeft: '20%',
		marginBottom: '20px'
	}
}));

FormikCheckbox.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	options: PropTypes.arrayOf(PropTypes.object),
	customHandleChange: PropTypes.func
};

export function FormikCheckbox({ label, name, options, customHandleChange = undefined }) {
	const classes = useStyles();
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const handleChange = useCallback(
		event => {
			const index = field.value.findIndex(item => item.id === parseInt(event.target.name, 10));
			const itemValue = {
				...field.value[index],
				is_checked: event.target.checked
			};
			const newValue = [...field.value];
			newValue[index] = itemValue;

			setFieldValue(name, newValue);
		},
		[field.value, name]
	);
	return (
		<div className={classes.flex}>
			<FormControl required error={meta.error} component="fieldset" className={classes.formControl}>
				<FormLabel component="legend">{label}</FormLabel>
				<FormGroup>
					{options.map(item => (
						<FormControlLabel
							key={item.id}
							control={
								<Checkbox
									checked={item.is_checked}
									onChange={customHandleChange || handleChange}
									name={item.id}
								/>
							}
							label={item.name}
						/>
					))}
				</FormGroup>
			</FormControl>
		</div>
	);
}

AutoCompleteTextField.propTypes = {
	id: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object),
	label: PropTypes.string,
	variant: PropTypes.string,
	name: PropTypes.string,
	useIdAsValue: PropTypes.bool
};

/**
 *
 * This is naked field, need to wrap it with div for styling
 * Unlike all the other forms where m-8 is the default
 *
 * @props useIdAsValue is like FormikSelect where it used the ID of an object for value
 */
function AutoCompleteTextField({ id, options, label, name, variant = 'outlined', useIdAsValue = false }) {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const [selectedVal, setSelectedVal] = useState({});

	const handleChange = React.useCallback(
		(event, newVal) => {
			if (useIdAsValue) {
				if (newVal) {
					setFieldValue(name, newVal.id);
				} else {
					setFieldValue(name, '');
				}
			} else {
				setFieldValue(name, newVal);
			}
		},
		[setFieldValue, name, useIdAsValue]
	);

	useEffect(() => {
		// Only used if we want to use id as value
		// If field.value has data then initiate the correct one
		if (useIdAsValue) {
			if (field.value) {
				const index = options.findIndex(item => item.id === parseInt(field.value, 10));
				setSelectedVal(options[index]);
			} else {
				setSelectedVal({});
			}
		}
	}, [field.value, options, useIdAsValue]);

	return (
		<Autocomplete
			id={id}
			options={options}
			onChange={handleChange}
			getOptionLabel={option => option.name || ''}
			value={useIdAsValue ? selectedVal : field.value}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					variant={variant}
					FormHelperTextProps={{ error: true }}
					helperText={meta.error ? String(meta.error) : null}
				/>
			)}
		/>
	);
}

export const FormikFileUpload = ({
	name,
	label,
	uploadUrl,
	onUploadSuccess,
	buttonStyle = '',
	getFileUrl,
	...props
}) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const classes = useStyles();
	const handleChange = useCallback(
		async e => {
			const files = [];
			const data = new FormData();
			data.append('userId', 1);
			for (let x = 0; x < e.currentTarget.files.length; x += 1) {
				files.push(e.currentTarget.files[x]);
				data.append('images[]', e.currentTarget.files[x]);
			}

			try {
				onUploadSuccess && onUploadSuccess(false);
				const payload = await axios.post(uploadUrl, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${localStorage.getItem('jwt_access_token')}`
					}
				});
				const { data: links } = payload;
				setFieldValue(name, links);
				getFileUrl && getFileUrl(links);
				onUploadSuccess && onUploadSuccess(true);
			} catch (err) {
				onUploadSuccess && onUploadSuccess(true);
				console.log(`There is an error in uploading the image${err.message}`);
			}
		},
		[name, setFieldValue]
	);

	return (
		<>
			<Button variant="contained" component="label" className={`${classes.instructions} ${buttonStyle}`}>
				{label || 'Upload File'}
				<input name={name} {...props} type="file" style={{ display: 'none' }} onChange={handleChange} />
			</Button>
			{meta.error ? <p className="text-danger">{meta.error}</p> : null}
			<div className="grid grid-cols-4">
				{field.value &&
					field?.value.map((value, index) => (
						<Card className={classes.root} key={index}>
							<CardMedia className={`${classes.media} bg-contain my-8`} image={value} title="Thumbnail" />
						</Card>
					))}
			</div>
		</>
	);
};

FormikFileUpload.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	uploadUrl: PropTypes.string.isRequired,
	onUploadSuccess: PropTypes.func,
	getFileUrl: PropTypes.func,
	buttonStyle: PropTypes.string
};

function areEqual(prevProps, nextProps) {
	if (prevProps.value === nextProps.value && prevProps.label === nextProps.label) {
		return true;
	}
	return false;
}

export const FormikTextField = React.memo(({ className, showFullError = true, ...props }) => {
	const classes = useStyles();
	const [field, meta] = useField(props);

	return (
		<>
			<TextField
				className={className || `${classes.margin} ${classes.instructions}`}
				{...field}
				{...props}
				FormHelperTextProps={{ error: true }}
				error={showFullError ? meta.error : null}
				helperText={meta.error ? String(meta.error) : null}
				aria-invalid={Boolean(meta.error)}
			/>
		</>
	);
}, areEqual);

FormikTextField.propTypes = {
	className: PropTypes.string,
	showFullError: PropTypes.bool
};

export const FormikNativeTime = ({ className, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<TextField
			type="time"
			defaultValue="22:30"
			className={className || 'mx-8 w-full'}
			InputLabelProps={{
				shrink: true
			}}
			inputProps={{
				step: 300 // 5 min
			}}
			helperText={meta.error ? String(meta.error) : null}
			{...field}
			{...props}
		/>
	);
};

FormikNativeTime.propTypes = {
	className: PropTypes.string
};

export const FormikSwitch = ({
	name,
	label,
	color,
	customHandleChange,
	customFunc,
	helperText = undefined,
	disabled = false,
	defaultChecked = false,
	sendSwitchToParent,
	labelPlacement = 'end',
	...props
}) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const classes = useStyles();

	const handleChange = useCallback(
		e => {
			if (customFunc) {
				customFunc(name, e.target.checked);
			}
			setFieldValue(name, e.target.checked);
			if (sendSwitchToParent) {
				sendSwitchToParent(e.target.checked);
			}
		},
		[name, setFieldValue]
	);

	return (
		<>
			<FormGroup className={classes.margin}>
				<FormControlLabel
					name={name}
					labelPlacement={labelPlacement}
					control={
						<Switch
							defaultChecked={defaultChecked}
							checked={field.value}
							onChange={customHandleChange || handleChange}
							className={color}
							disabled={disabled}
							{...props}
						/>
					}
					label={label}
				/>
				{helperText && <FormHelperText error>{helperText}</FormHelperText>}
			</FormGroup>
			{meta.error ? <p className="text-warning">{meta.error}</p> : null}
		</>
	);
};

FormikSwitch.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	color: PropTypes.string,
	customHandleChange: PropTypes.func,
	helperText: PropTypes.string,
	customFunc: PropTypes.func,
	disabled: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	sendSwitchToParent: PropTypes.func,
	labelPlacement: PropTypes.string
};

export const FormikSelect = React.memo(
	({
		label,
		labelId,
		selectId,
		options,
		name,
		className,
		helperText = 'Select value from the drop down',
		customHandleChange,
		...props
	}) => {
		const [field, meta] = useField(name);
		const classes = useStyles();
		const { setFieldValue } = useFormikContext();
		const handleChange = useCallback(
			e => {
				setFieldValue(name, e.target.value);
				if (customHandleChange) {
					customHandleChange(e.target.value);
				}
			},
			[name, setFieldValue]
		);

		const renderHelperText = useCallback(() => {
			if (typeof helperText === 'function') {
				return helperText();
			}
			return helperText;
		}, [helperText]);

		return (
			<FormControl variant="outlined" className={clsx(className || classes.margin)}>
				<InputLabel id={labelId}>{label}</InputLabel>
				<Select
					{...field}
					{...props}
					label={label}
					labelId={labelId}
					id={selectId}
					onChange={handleChange}
					name={name}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{options.map(value => (
						<MenuItem value={value.id || value.name} key={value.id || value.name}>
							{value.name}
						</MenuItem>
					))}
				</Select>
				<FormHelperText error={Boolean(meta.error)}>
					{meta.error ? String(meta.error) : renderHelperText()}
				</FormHelperText>
			</FormControl>
		);
	}
);

FormikSelect.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	labelId: PropTypes.string.isRequired,
	selectId: PropTypes.string.isRequired,
	className: PropTypes.string,
	helperText: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	customHandleChange: PropTypes.func
};

export const FormikRadioButton = ({ name, options, label, defaultValue = 0, disabled = false, ...props }) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		setFieldValue(name, field.value || defaultValue);
	}, []);

	const handleChange = useCallback(
		e => {
			setFieldValue(name, parseInt(e.target.value, 10));
		},
		[name, setFieldValue]
	);

	return (
		<>
			<FormControl component="fieldset">
				<FormLabel component="legend" required>
					{label}
				</FormLabel>
				<RadioGroup {...field} {...props} name={name} onChange={handleChange}>
					{options.map(value => (
						<FormControlLabel
							control={<Radio disabled={disabled} />}
							label={value.name}
							value={value.id}
							key={value.id}
						/>
					))}
				</RadioGroup>
				{meta.error ? <FormHelperText error={meta.error}>{meta.error}</FormHelperText> : null}
			</FormControl>
		</>
	);
};

FormikRadioButton.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.string
};

export const FeedbackRadioButton = ({
	name,
	options,
	image,
	label,
	requestType,
	disabled = false,
	editMode,
	...props
}) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const [selectedName, setSelectedName] = useState('');
	const handleChange = useCallback(
		e => {
			setSelectedName(e.target.value);
			if (name === 'incident_main_type_id') {
				requestType(e.target.value);
			}
			setFieldValue(name, parseInt(e.target.value, 10));
		},
		[name, setFieldValue]
	);

	useEffect(() => {
		if (editMode || field.value) {
			setSelectedName(String(field.value));
		}
	}, [field.value]);

	const classes = useStyles();
	const borderClassName = id => (String(id) === selectedName ? '1px solid rgb(197 194 194)' : 'none');
	const isSelected = id => String(id) === selectedName;
	return (
		<FormControl className="feedback-select-container">
			{/* <FormLabel component="legend" required> */}
			{/*	{label} */}
			{/* </FormLabel> */}
			<RadioGroup style={{ padding: '20px' }} {...field} {...props} name={name} onChange={handleChange} row>
				{options.map(value => (
					<FormControlLabel
						control={
							<Radio disabled={disabled} className={`${classes.customRadio} radio-container-ipad`} />
						}
						label={
							<div className="feedback-select-inner-container">
								<div>
									<img
										className={`${classes.feedbackRadioIcon} radioIcon-ipad`}
										alt=""
										src={feedbackFormIcons[value.name].icon}
									/>
								</div>
								<div className="feedback-radio-container-text">
									<Typography variant="h6">{value.name}</Typography>
									<Typography
										className={`${classes.feedbackRadioDescription} radioDescription`}
										variant="caption"
									>
										{feedbackFormIcons[value.name].description}
									</Typography>
								</div>
								<div className="feedback-checkbox-container">
									<Radio
										value={value.id.toString()}
										checked={isSelected(value.id)}
										onChange={handleChange}
									/>
								</div>
							</div>
						}
						value={value.id.toString()}
						key={value.id}
						style={{ border: borderClassName(value.id) }}
						className={classes.customFeedbackRadioContainer}
					/>
				))}
			</RadioGroup>
			{meta.error ? <FormHelperText error={meta.error}>{meta.error}</FormHelperText> : null}
		</FormControl>
	);
};

FeedbackRadioButton.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	requestType: PropTypes.func,
	editMode: PropTypes.bool,
	image: PropTypes.string
};

export const IncidentReportRadioButton = ({
	name,
	options,
	label,
	requestType,
	disabled = false,
	editMode,
	...props
}) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const [selectedName, setSelectedName] = useState('');
	const handleChange = useCallback(
		e => {
			setSelectedName(e.target.value);
			if (name === 'incident_main_type_id') {
				requestType(e.target.value);
			}
			setFieldValue(name, parseInt(e.target.value, 10));
		},
		[name, setFieldValue]
	);

	useEffect(() => {
		if (editMode || field.value) {
			setSelectedName(String(field.value));
		}
	}, [field.value]);

	const classes = useStyles();
	const borderClassName = id => (String(id) === selectedName ? '1px solid rgb(197 194 194)' : 'none');

	return (
		<FormControl component="fieldset">
			{/* <FormLabel component="legend" required> */}
			{/*	{label} */}
			{/* </FormLabel> */}
			<RadioGroup style={{ padding: '20px' }} {...field} {...props} name={name} onChange={handleChange} row>
				{options.map(value => (
					<FormControlLabel
						control={
							<Radio disabled={disabled} className={`${classes.customRadio} radio-container-ipad`} />
						}
						label={
							<div className={`${classes.iconContainer} radioIcon-container-ipad`}>
								<img
									className={`${classes.radioIcon} radioIcon-ipad`}
									alt=""
									src={incidentFormIcons[value.label]}
								/>
								<div className="radio-container-text">
									<Typography variant="h6">{value.name}</Typography>
									<Typography
										className={`${classes.radioDescription} radioDescription`}
										variant="caption"
									>
										{value.description}
									</Typography>
								</div>
							</div>
						}
						value={value.id.toString()}
						key={value.id}
						style={{ border: borderClassName(value.id) }}
						className={`${classes.customRadioContainer} incident-ipad`}
					/>
				))}
			</RadioGroup>
			{meta.error ? <FormHelperText error={meta.error}>{meta.error}</FormHelperText> : null}
		</FormControl>
	);
};

IncidentReportRadioButton.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	requestType: PropTypes.func,
	editMode: PropTypes.bool
};

export const FormikPicker = ({
	format = null,
	dateTime = 'true',
	name,
	label,
	variant = 'dialog',
	inputVariant = 'standard',
	className,
	disabled = false,
	reload,
	...props
}) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();

	const handlePickerChange = useCallback(
		value => {
			// Format value according to Moment JS in YYYY-MM-DDTHH:mm:iiZ
			const time = value ? value.format() : null;
			setFieldValue(name, time);
		},
		[name, setFieldValue]
	);

	return (
		<MuiPickersUtilsProvider utils={DayjsUtils}>
			<div className={className || 'm-8'}>
				{dateTime ? (
					<KeyboardDateTimePicker
						ampm
						mask="__-__-____ __:__"
						autoOk
						className="w-full"
						format={format || 'DD-MM-YYYY HH:mm'}
						onChange={handlePickerChange}
						name={name}
						label={label}
						variant={variant}
						inputVariant={inputVariant}
						value={field.value}
						disabled={disabled}
						{...props}
					/>
				) : (
					<KeyboardDatePicker
						className="w-full"
						format={format || 'DD-MM-YYYY'}
						mask="__-__-____"
						autoOk
						onChange={handlePickerChange}
						name={name}
						label={label}
						variant={variant}
						inputVariant={inputVariant}
						value={field.value}
						disabled={disabled}
						style={{ width: reload ? '90%' : '100%' }}
						{...props}
					/>
				)}
				{reload}
				{Boolean(meta.error) && <p className="m-8 text-xs text-red-600">{String(meta.error)}</p>}
			</div>
		</MuiPickersUtilsProvider>
	);
};

FormikPicker.propTypes = {
	format: PropTypes.string,
	dateTime: PropTypes.bool,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	variant: PropTypes.string,
	inputVariant: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	reload: PropTypes.node
};

export const FormikTime = ({ name, label, variant, className, ampm = true, inputVariant }) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const classes = useStyles();

	const handleTimeChange = useCallback(
		value => {
			setFieldValue(name, value.format('HH:mm'));
		},
		[name, setFieldValue]
	);
	return (
		<MuiPickersUtilsProvider utils={DayjsUtils}>
			<TimePicker
				className={className || classes.margin}
				autoOk
				onChange={handleTimeChange}
				name={name}
				label={label}
				variant={variant}
				inputVariant={inputVariant || 'standard'}
				value={dayjs(field.value, 'HH:mm')}
				ampm={ampm}
			/>
			{meta.error ? <p className="m-8 text-xs text-red-600">{String(meta.error)}</p> : null}
		</MuiPickersUtilsProvider>
	);
};

FormikTime.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	variant: PropTypes.string,
	className: PropTypes.string,
	inputVariant: PropTypes.string,
	ampm: PropTypes.bool
};

export const BootstrapInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3)
		}
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: '1.4rem',
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
		}
	}
}))(InputBase);

export function Signature({ name, ...props }) {
	const sigPad = useRef(null);
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const classes = useStyles();

	const handleClear = useCallback(() => {
		sigPad.current.clear();
	}, [sigPad]);

	const handleEnd = () => {
		setFieldValue(name, sigPad.current.toDataURL());
	};

	return (
		<div className={`${classes.margin} ${classes.instructions}`}>
			<Grid spacing={3} container>
				<Grid item xs={12}>
					<h4 className="text-bold">Signature</h4>
				</Grid>
				<Grid item xs={12}>
					<div className="sigCanvas">
						<SignatureCanvas
							name={name}
							penColor="black"
							canvasProps={{ className: 'sigPad' }}
							ref={sigPad}
							onEnd={handleEnd}
							{...field}
							{...props}
						/>
					</div>
				</Grid>
				<Grid item xs={3}>
					<Button
						variant="contained"
						component="label"
						className={classes.instructions}
						onClick={handleClear}
					>
						Clear
					</Button>
				</Grid>
			</Grid>
			{meta.error ? <p className="m-8 text-xs text-red-600">{meta.error}</p> : null}
		</div>
	);
}

Signature.propTypes = {
	name: PropTypes.string
};

const ArrayContext = React.createContext({});

/**
 *
 *
 * @export
 * @param {*} { name (initial array name in Const), children (children of the arrays) }
 * @returns Children provided with index, remove and push function from formik FieldArray
 */
export function FormikArray({ name, defaultValue, children }) {
	const [field] = useField(name);
	const classes = useStyles();
	return (
		<FieldArray
			name={name}
			render={({ remove, push }) => (
				<>
					{field.value.length > 0 &&
						field.value.map((value, index) => {
							const formikFieldHelpers = {
								index
							};
							return (
								<Slide direction="up" in mountOnEnter unmountOnExit>
									<div className="flex flex-col" key={index}>
										<div className={classes.margin}>
											<Button
												variant="contained"
												component="label"
												className={`${classes.instructions} ${classes.margin}`}
												onClick={() => {
													push(defaultValue);
												}}
											>
												Add New
											</Button>
											{field.value.length > 1 && (
												<Button
													variant="contained"
													component="label"
													className={`${classes.instructions} ${classes.margin}`}
													onClick={() => remove(index)}
												>
													Remove
												</Button>
											)}
										</div>
										<ArrayContext.Provider value={formikFieldHelpers}>
											{typeof children === 'function' ? children(formikFieldHelpers) : children}
										</ArrayContext.Provider>
									</div>
								</Slide>
							);
						})}
				</>
			)}
		/>
	);
}

FormikArray.propTypes = {
	name: PropTypes.string,
	defaultValue: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired])
};

export function FormikHazardTable({ name, scoreField, data }) {
	const { setFieldValue } = useFormikContext();
	const classes = useStyles();

	// Because components are looking to get a function that has THEIR
	// Param, not our param, thus we have to return a function that has no param or event param
	const handleClick = useCallback(
		(val, score) => () => {
			setFieldValue(name, val);
			setFieldValue(scoreField, score);
		},
		[name, scoreField]
	);

	return (
		<div className="gridContainer">
			<Grid container spacing={0}>
				<Grid item xs={2} className="columnTitle">
					<Grid container alignItems="center" className={classes.full}>
						<Grid item xs={12}>
							<p className="font-semibold text-center text-md">Likelihood</p>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={10}>
					<Grid container spacing={0} alignItems="center" className="columnTitle">
						<Grid item xs={12}>
							<div className={classes.full}>
								<p className="font-semibold text-center text-md">Consequence</p>
							</div>
						</Grid>
					</Grid>
					<Grid container alignItems="center" alignContent="center" className="columnTitle">
						<Grid item xs>
							<p className="font-semibold text-center text-md">Negligible</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center text-md">Insignificant</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center text-md">Moderate</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center text-md">Major</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center text-md">Catastrophic</p>
						</Grid>
					</Grid>
					<Grid container alignItems="center" className="columnTitle">
						<Grid item xs>
							<p className="font-semibold text-center">No Medical treatment required</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center">
								Reversible disability or impairment requiring hospitalization
							</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center">
								Disability or impairment (&lt;30%) to 1 or more persons
							</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center">
								Single fatality or severe disability (&gt;30%) to 1 or more persons
							</p>
						</Grid>
						<Grid item xs>
							<p className="font-semibold text-center">
								Multiple fatalities or significant irreversible effects (&gt; 30%) to &gt;1 person
							</p>
						</Grid>
					</Grid>
				</Grid>
				{data.likelihoodTable.map(value => (
					<Grid container key={value.row} className="rowTitle">
						<Grid item xs={2}>
							<p className="font-semibold text-center">{value.header}</p>
						</Grid>
						<Grid item xs={10}>
							<ButtonGroup
								size="large"
								color="primary"
								aria-label="large outlined primary"
								className={classes.full}
							>
								{value.items.map(item => (
									<Button
										key={item.value}
										className={`btn${item.title} ${classes.full} text-xs font-semibold`}
										onClick={handleClick(item.value, item.score)}
									>
										{item.title}
									</Button>
								))}
							</ButtonGroup>
						</Grid>
					</Grid>
				))}
			</Grid>
		</div>
	);
}

FormikHazardTable.propTypes = {
	name: PropTypes.string,
	scoreField: PropTypes.string,
	data: PropTypes.shape({
		likelihoodTable: PropTypes.arrayOf(PropTypes.object).isRequired
	})
};

export const EmergencyRadioButton = ({ name, options, label, requestType, disabled = false, editMode, ...props }) => {
	const [field, meta] = useField(name);
	const { setFieldValue } = useFormikContext();
	const [selectedName, setSelectedName] = useState('');
	const handleChange = useCallback(
		e => {
			setSelectedName(e.target.value);
			requestType(e.target.value);

			setFieldValue(name, parseInt(e.target.value, 10));
		},
		[name, setFieldValue]
	);

	useEffect(() => {
		if (editMode || field.value) {
			setSelectedName(String(field.value));
		}
	}, [field.value]);

	const classes = useStyles();
	const borderClassName = id => (String(id) === selectedName ? '1px solid rgb(197 194 194)' : 'none');

	return (
		<FormControl component="fieldset">
			<RadioGroup style={{ padding: '20px' }} {...field} {...props} name={name} onChange={handleChange} row>
				{options &&
					options.map(value => (
						<FormControlLabel
							control={
								<Radio disabled={disabled} className={`${classes.customRadio} radio-container-ipad`} />
							}
							label={
								<div className={`${classes.iconContainer} radioIcon-container-ipad`}>
									<img
										className={`${classes.customEmergencyRadio} radioIcon-ipad`}
										alt=""
										src={EmergencyFormIcons[value.label]}
									/>
									<div className="radio-container-text">
										<Typography variant="h6">{value.name}</Typography>
										<Typography
											className={`${classes.radioDescription} radioDescription`}
											variant="caption"
										>
											{value.description}
										</Typography>
									</div>
								</div>
							}
							value={value.id.toString()}
							key={value.id}
							style={{ border: borderClassName(value.id) }}
							className={`${classes.customRadioContainer} incident-ipad`}
						/>
					))}
			</RadioGroup>
			{meta.error ? <FormHelperText error={meta.error}>{meta.error}</FormHelperText> : null}
		</FormControl>
	);
};

EmergencyRadioButton.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	requestType: PropTypes.func,
	editMode: PropTypes.bool
};

export const EmergencyLevelRadioButton = ({
	name,
	options,
	label,
	requestType,
	disabled = false,
	editMode,
	children,
	selectedEmergencyLevel,
	...props
}) => {
	const [selectedName, setSelectedName] = useState('');
	const handleChange = (e, value) => {
		console.log('value', value);
		setSelectedName(value.toString());
		requestType(value);
	};

	useEffect(() => {
		if (editMode || selectedEmergencyLevel) {
			setSelectedName(selectedEmergencyLevel.toString());
		}
	}, [selectedEmergencyLevel]);
	return (
		<FormControl component="fieldset" className="w-full" label="Start">
			<RadioGroup {...props} name={name} onChange={handleChange} defaultValue={selectedEmergencyLevel}>
				{options &&
					options.map(value => (
						<div className="flex flex-col items-center justify-between w-full m-4 shadow">
							<FormControlLabel
								control={<Radio />}
								label={
									<div className="flex items-center">
										<img
											alt={value.name}
											src={EmergencyFormIcons[value.label]}
											className="p-4 mr-16"
										/>
										<Typography variant="h6">{value.name}</Typography>
									</div>
								}
								value={value.id.toString()}
								key={value.name}
								labelPlacement="start"
								className="flex justify-between w-full p-4 m-4 "
							/>
							<Collapse className="w-full p-12 mx-md" in={value.id == selectedName}>
								{children}
							</Collapse>
						</div>
					))}
			</RadioGroup>
		</FormControl>
	);
};

EmergencyLevelRadioButton.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	requestType: PropTypes.func,
	editMode: PropTypes.bool,
	children: PropTypes.node,
	selectedEmergencyLevel: PropTypes.number
};

export default {
	FormikArray,
	FormikTextField,
	FormikHazardTable,
	FormikFileUpload,
	FormikPicker,
	FormikSelect,
	AutoCompleteTextField,
	FormikRadioButton,
	FormikTime,
	FormikNativeTime,
	FormikSwitch,
	FormikCheckbox
};
