import * as yup from 'yup';

export const validationGym = yup.object().shape({
	location_id: yup.number().required('Required'),
	first_name: yup.string().required('Required'),
	last_name: yup.string().required('Required'),
	email: yup.string().email().required('Required'),
	phone: yup.string().required('Required'),
	signature: yup.string().required('Required')
});

export const formValues = {
	location_id: '',
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	signature: '',
	dob: null
};
